<!--
 * @Author: Libra
 * @Date: 2022-08-19 10:21:17
 * @LastEditTime: 2023-02-06 13:44:26
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/views/mobile/login.vue
-->
<!--
 * @Author: Libra
 * @Date: 2021-08-12 10:01:25
 * @LastEditTime: 2022-06-13 10:57:34
 * @LastEditors: Libra
 * @Description: 公开作答
 * @FilePath: /stone-exam-ui/src/views/PublicLogin.vue
-->
<template>
  <div class="common-bg login">
    <div
      class="header-container"
      style="width: 1230px"
    >
      <div class="img-contaienr">
        <img class="logo" v-if="logo" :src="logo" alt="企业LOGO" />
        <img
          class="logo"
          v-else-if="logo === null"
          src="../../assets/images/logo_igp.png"
          alt="企业LOGO"
        />
        <span v-else-if="logo === undefined"> </span>
      </div>
    </div>
    <div class="container">
      <div class="left">
        <div class="title">
          在线考试
        </div>
        <div class="welcome">欢迎你参加在线考试！</div>
        <div class="tips">
          <div class="tip">请使用手机微信扫码作答；</div>
          <div class="tip">微信软件版本需8.0以上；</div>
          <div class="tip">作答时请不要退出小程序页面；</div>
          <div class="tip">注意考试纪律，诚信作答；</div>
          <div class="tip">手机网络故障时，重新扫码继续作答即可。</div>
        </div>
      </div>
      <div class="right login-box">
        <div class="title">微信扫码登录</div>
        <div class="qr-container">
          <vue-qr
            :size="800"
            style="width: 150px;height: 150px;transform: translateY(0);max-width: 300px"
            :text="codeStr"
          ></vue-qr>
          <div class="refresh-qr" v-if="isFailure">
            <span class="failure">二维码已失效</span>
            <el-button type="danger" @click="refresh">请点击刷新</el-button>
          </div>
        </div>
        <span style="margin-top:10px;">请用手机微信扫码</span>
      </div>
    </div>
    <div class="footer">
    主办方：{{ company }}
    <span class="line">|</span>
    本网站由<a
      style="color: #717171"
      href="https://www.iguokao.com"
      target="view_window"
      >国考云</a
    >考试系统提供技术支持
  </div>
  </div>
</template>
<script>
import api from '../../api/api'
import VueQr from 'vue-qr'
import { sys_file_host } from '../../api/config'

export default {
  components: {
    VueQr
  },
  data() {
    return {
      codeStr: '',
      isFailure: false,
      qrTimer: null,
      logo: undefined,
      company: ''
    }
  },
  created() {
    this.setDocumentTitle()
    this.getQrToken()
    this.refreshQR()
  },
  methods: {
    setDocumentTitle() {
      document.title = '国考云在线考试'
    },
    async getQrToken() {
      const candidateUuid = this.$route.query.candidateUuid
      const res = await api.getQrToken(candidateUuid)
      if (res.code === 0) {
        const env = process.env.NODE_ENV
        // const env = 'beta'
        const host = 'https://exam-file.iguokao.com/index?secret='
        this.codeStr = `${host}${res.data.token}&env=${env}`
        this.logo = `${sys_file_host}${res.data.logo}`
        this.company = res.data.companyShortName
      } else {
        this.$message.error('获取二维码失败')
      }
    },
    refreshQR() {
      this.qrTimer = setTimeout(() => {
        this.isFailure = true
        clearTimeout(this.qrTimer)
      }, 60000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.qrTimer)
      })
    },
    async refresh() {
      await this.getQrToken()
      this.isFailure = false
      this.refreshQR()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/theme/variables.scss";
.login {
  box-sizing: border-box;
  position: relative;
  ::v-deep .private-rule {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
    .el-dialog__body {
      height: 600px;
      overflow: auto;
    }
  }
  .container {
    overflow: hidden;
    min-height: calc(100vh - 200px);
    .left {
      float: left;
      background-image: url("~@/assets/images/illustration.png");
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: 300px;
      color: #4d4d4d;
      padding-top: 80px;
      width: 700px;
      box-sizing: border-box;
      padding-bottom: 20px;
      .title {
        color: #333;
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .exam-time {
        font-size: 16px;
        margin-bottom: 80px;
      }
      .welcome {
        font-size: 24px;
        margin-bottom: 44px;
      }
      .tips {
        font-size: 14px;
        line-height: 2.2;
        margin-bottom: 36px;
      }
      .left-time {
        font-size: 18px;
        font-weight: bold;
        .count-down {
          font-size: 20px;
          color: $primary-color;
        }
      }
    }
    .right.login-box {
      float: right;
      margin-top: 120px;
      margin-bottom: 0px;
      width: 480px;
      background-color: #fff;
      border-radius: 4px;
      padding: 45px;
      box-sizing: border-box;
      box-shadow: 0 0 18px #ddd;
      display: flex;
      align-items: center;
      flex-direction: column;
      .title {
        font-size: 22px;
        text-align: center;
        color: $primary-color;
        margin-bottom: 48px;
      }
      .qr-container {
        position: relative;
        width: 150px;
        height: 150px;
        .refresh-qr {
          position: absolute;
          width: 150px;
          height: 150px;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .failure {
            font-weight: bold;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
.header-container {
    height: 75px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    .img-contaienr {
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        max-height: 75px;
        height: 65px;
        max-width: 300px;
        width: auto;
        display: block;
        top: 0;
        transform: translate(0);
      }
    }
  }
  .footer {
  width: 100%;
  background-color: transparent;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #acacac;
  font-size: 14px;
  .line {
    margin: 0 8px;
  }
}
</style>
