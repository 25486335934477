var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "common-bg login" }, [
    _c(
      "div",
      { staticClass: "header-container", staticStyle: { width: "1230px" } },
      [
        _c("div", { staticClass: "img-contaienr" }, [
          _vm.logo
            ? _c("img", {
                staticClass: "logo",
                attrs: { src: _vm.logo, alt: "企业LOGO" },
              })
            : _vm.logo === null
            ? _c("img", {
                staticClass: "logo",
                attrs: {
                  src: require("../../assets/images/logo_igp.png"),
                  alt: "企业LOGO",
                },
              })
            : _vm.logo === undefined
            ? _c("span")
            : _vm._e(),
        ]),
      ]
    ),
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _c("div", { staticClass: "right login-box" }, [
        _c("div", { staticClass: "title" }, [_vm._v("微信扫码登录")]),
        _c(
          "div",
          { staticClass: "qr-container" },
          [
            _c("vue-qr", {
              staticStyle: {
                width: "150px",
                height: "150px",
                transform: "translateY(0)",
                "max-width": "300px",
              },
              attrs: { size: 800, text: _vm.codeStr },
            }),
            _vm.isFailure
              ? _c(
                  "div",
                  { staticClass: "refresh-qr" },
                  [
                    _c("span", { staticClass: "failure" }, [
                      _vm._v("二维码已失效"),
                    ]),
                    _c(
                      "el-button",
                      { attrs: { type: "danger" }, on: { click: _vm.refresh } },
                      [_vm._v("请点击刷新")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c("span", { staticStyle: { "margin-top": "10px" } }, [
          _vm._v("请用手机微信扫码"),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "footer" }, [
      _vm._v(" 主办方：" + _vm._s(_vm.company) + " "),
      _c("span", { staticClass: "line" }, [_vm._v("|")]),
      _vm._v(" 本网站由"),
      _c(
        "a",
        {
          staticStyle: { color: "#717171" },
          attrs: { href: "https://www.iguokao.com", target: "view_window" },
        },
        [_vm._v("国考云")]
      ),
      _vm._v("考试系统提供技术支持 "),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 在线考试 ")]),
      _c("div", { staticClass: "welcome" }, [_vm._v("欢迎你参加在线考试！")]),
      _c("div", { staticClass: "tips" }, [
        _c("div", { staticClass: "tip" }, [_vm._v("请使用手机微信扫码作答；")]),
        _c("div", { staticClass: "tip" }, [_vm._v("微信软件版本需8.0以上；")]),
        _c("div", { staticClass: "tip" }, [
          _vm._v("作答时请不要退出小程序页面；"),
        ]),
        _c("div", { staticClass: "tip" }, [_vm._v("注意考试纪律，诚信作答；")]),
        _c("div", { staticClass: "tip" }, [
          _vm._v("手机网络故障时，重新扫码继续作答即可。"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }