var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "放弃参加",
            visible: _vm.dialogFormVisible,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.refuse } },
            [
              _c(
                "el-form-item",
                { staticClass: "form-item", attrs: { label: "放弃原因" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择放弃原因" },
                      model: {
                        value: _vm.refuse.reason,
                        callback: function ($$v) {
                          _vm.$set(_vm.refuse, "reason", $$v)
                        },
                        expression: "refuse.reason",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "已拿到offer", value: "已拿到offer" },
                      }),
                      _c("el-option", {
                        attrs: { label: "时间不合适", value: "时间不合适" },
                      }),
                      _c("el-option", {
                        attrs: { label: "没有网络环境", value: "没有网络环境" },
                      }),
                      _c("el-option", {
                        attrs: { label: "其他", value: "其他" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "原因描述" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder:
                        "亲，请告知我们放弃的原因吧，以便我们改进工作，为大家提供更好的服务！",
                      rows: 4,
                    },
                    model: {
                      value: _vm.refuse.reasonDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.refuse, "reasonDetail", $$v)
                      },
                      expression: "refuse.reasonDetail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("CustomButton", {
                staticClass: "gap",
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  title: "取消",
                  clickMethod: () => (_vm.dialogFormVisible = false),
                },
              }),
              _c("CustomButton", {
                staticClass: "gap",
                attrs: {
                  title: "确认",
                  isPlain: false,
                  clickMethod: _vm.refuseConfirm,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "邮件确认状态",
            visible: _vm.dialogVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("i", { staticClass: "iconfont iconduigou1" }),
            _c("div", { staticClass: "right" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("已收到邮件反馈！"),
              ]),
              _c("span", { staticClass: "result" }, [_vm._v("已放弃参加")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }