<!--
 * @Author: Libra
 * @Date: 2021-07-06 17:26:59
 * @LastEditTime: 2021-08-20 15:42:05
 * @LastEditors: Libra
 * @Description:拒绝邀请页面
 * @FilePath: /stone-exam-ui/src/views/invite/Refuse.vue
-->
<template>
  <div>
    <el-dialog
      title="放弃参加"
      :visible.sync="dialogFormVisible"
      class="dialog"
      center
    >
      <el-form :model="refuse">
        <el-form-item label="放弃原因" class="form-item">
          <el-select v-model="refuse.reason" placeholder="请选择放弃原因">
            <el-option label="已拿到offer" value="已拿到offer"></el-option>
            <el-option label="时间不合适" value="时间不合适"></el-option>
            <el-option label="没有网络环境" value="没有网络环境"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因描述">
          <el-input
            type="textarea"
            placeholder="亲，请告知我们放弃的原因吧，以便我们改进工作，为大家提供更好的服务！"
            :rows="4"
            v-model="refuse.reasonDetail"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <CustomButton
          class="gap"
          title="取消"
          :clickMethod="() => (dialogFormVisible = false)"
          style="margin-right: 10px"
        ></CustomButton>
        <CustomButton
          class="gap"
          title="确认"
          :isPlain="false"
          :clickMethod="refuseConfirm"
        ></CustomButton>
      </div>
    </el-dialog>
    <el-dialog title="邮件确认状态" :visible.sync="dialogVisible" width="400px">
      <div class="content">
        <i class="iconfont iconduigou1"></i>
        <div class="right">
          <span class="title">已收到邮件反馈！</span>
          <span class="result">已放弃参加</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton'
import Api from '@/api/api'
export default {
  data() {
    return {
      refuse: {
        reason: '',
        reasonDetail: ''
      },
      dialogVisible: false,
      dialogFormVisible: true
    }
  },
  components: {
    CustomButton
  },
  methods: {
    // 放弃参加
    async refuseConfirm() {
      const candidateUuid = this.$route.query.candidateUuid
      this.dialogFormVisible = false
      const res = await Api.candidateConfirm(
        `candidateUuid=${candidateUuid}&confirmState=20&refuseMessage=${this.refuse.reasonDetail}&refuseReason=${this.refuse.reason}`
      )
      if (res.code === 0) {
        this.dialogVisible = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  .iconfont {
    font-size: 60px;
    color: #cb2a1d;
    margin-right: 20px;
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    .title {
      font-size: 14px;
    }
    .result {
      font-size: 18px;
      font-weight: bold;
      margin-top: 10px;
    }
  }
}
</style>
